<template>
   <div style="width:calc(100% - 10px);height: 630px;margin: 0 auto; border: 1px solid #DCDFE6;margin-top: 5px;">
        <div style="width: 100%;height: 60px;border-bottom: 1px solid #DCDFE6;display: flex;">
            <div style="display: flex;width: calc(100% - 60px);height: 30px;margin: 0  auto;margin-top: 15px;">
                <div style="width: 184px;line-height: 30px;font-size: 18px;font-weight: 600;color: #4D4D4D;">提取余额到银行卡</div>
                <div style="width: calc(100% - 184px - 65px);line-height: 30px;color: #C6C4C6;">余额：<span style="font-size: 18px;color: #FC491E;">{{acctBalance.info.acct_balance}}</span> 元</div>
                <div @click="withdrawaShow = true" @mouseover="withdrawaColor = true" @mouseleave="withdrawaColor = false" :style="withdrawaColor == true?'color: #FBC11A':'color: #5088CC;'" style="width: 65px;line-height: 30px;font-size: 16px;cursor: pointer;">提现记录</div>
            </div>
        </div>

       <!--提现中-->
       <div v-if="withdrawaResult == 0">
           <div style="width: 100%;height: auto;display: flex;">
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 40px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;">提现至:</div>
                   <div style="width: calc(100% - 184px);height: auto;">
                       <div style="width: calc(100% - 2px);;height: 46px;display: flex;border: 1px solid #DCDFE6;font-size: 14px;margin-bottom: 15px;margin-left: 20px;">
                           <div style="margin-top: 7px;margin-left: 30px;">
                               <el-radio v-model="radio" label="1">中国{{acctBalance.bankName}}</el-radio>
                           </div>
                           <div style=" margin-top: 7px;">
                               <img style="width: 30px;height: 20px;margin-top: 6px;"  :src="acctBalance.bankUrl">
                           </div>
                           <div style="width: 500px; line-height: 34px; margin-top: 7px;padding-left: 5px;">
                               尾号：{{acctBalance.cardId}} <span style="color: #5088CC;">(快捷)</span>
                           </div>
                           <div style="line-height: 34px; margin-top: 7px;padding-left: 5px;color: #c1c1c1;">
                               单笔50000.00元 每日
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           <div style="width: 100%;">
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 20px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;">提现金额:</div>
                   <div style="width: 144px;line-height: 40px;margin-left: 20px;">
                       <el-input type="number" v-model="money" @input="checkMoney" placeholder="请输入金额"></el-input>
                   </div>
                   <div style="width: 234px;line-height: 40px;margin-left: 20px;font-size: 12px;color: #c1c1c1;">
                       (最多可提现:<span style="color: #1D82FE;">{{acctBalance.info.acct_balance}}元</span>,次日到账不收取手续费)
                   </div>
               </div>
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 40px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;">支付密码:</div>
                   <div style="width: 144px;line-height: 40px;margin-left: 20px;">
                       <el-input v-model="payPwd" placeholder="请输入支付密码"></el-input>
                   </div>
                   <div @click="pwdShow = true" @mouseover="pwdColor = true" @mouseleave="pwdColor = false" :style="pwdColor == true?'color: red':'color: #5088CC;'" style="width: 144px;line-height: 40px;margin-left: 20px;font-size: 12px;cursor: pointer;">
                       忘记密码
                   </div>
               </div>
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 40px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;"></div>
                   <div style="width: 144px;line-height: 40px;margin-left: 20px;">
                       <el-button @click="alpplyBankCarCash" type="primary">下一步</el-button>
                   </div>
               </div>
           </div>
       </div>

       <!--提现成功提示-->
       <div v-if="withdrawaResult == 1">
           <div style="width: 100%;height: 96px;background: #FFF9D9;display: flex;">
               <div style="width: 40px;height: 40px;margin-top: 28px;margin-left: 30px;">
                   <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/djs.png">
               </div>
               <div style="width: calc(100% - 75px);line-height: 96px;padding-left: 15px;font-weight: bold;">
                   提现申请已提交，等待银行处理。
               </div>
           </div>
           <div style="width: calc(100% - 85px);line-height: 35px;padding-left: 35px;font-size: 14px;color: #888A88;">如果银行信息填写错误，导致提现失败，资金将自动退还到您的账户余额。</div>
           <div style="width: 100%;height: 60px;display: flex;margin-top: 30px;">
               <div style="width: calc(30% - 35px);height: 100%;padding-left: 35px;color: #62B312;">
                   <div style="width: 100%;font-size: 14px;font-weight: bold;">提现申请</div>
                   <div style="width: 100%;font-size: 14px;color: #888A88;">{{cashResult.cashTime}}</div>
               </div>
               <div style="width: 30%;height: 100%;color: #62B312;">
                   <div style="width: 100%;font-size: 14px;font-weight: bold;">银行处理</div>
                   <div style="width: 100%;font-size: 14px;color: #888A88;">{{cashResult.cashTime}}</div>
               </div>
               <div style="width: 30%;height: 100%;color: #62B312;">
                   <div style="width: 100%;font-size: 14px;color: #888A88;">提现成功</div>
                   <div style="width: 100%;font-size: 14px;color: #888A88;">预计{{cashResult.expectTime}}后到账</div>
               </div>
           </div>
           <div style="width: calc(100% - 85px);line-height: 35px;padding-left: 35px;"><el-progress :percentage="35" status="warning"></el-progress></div>
           <div style="width: calc(100% - 85px);line-height: 65px;padding-left: 35px;font-size: 14px;color: #888A88;display: flex;">
               <div @click="continueToWithdraw" style="width: 70px;color: blue;cursor: pointer;">继续提现</div>
               <div @click="withdrawaShow = true" style="width: 100px;color: blue;cursor: pointer;">查看提现记录</div>
               <div @click="a('/')" style="width: 100px;color: blue;cursor: pointer;">返回首页</div>
           </div>
       </div>

       <!--提现记录弹窗-->
       <el-dialog title="提现记录" :close-on-click-modal="false" :visible.sync="withdrawaShow" v-if="withdrawaShow == true" width="60%">
            <ExtractRecord></ExtractRecord>
       </el-dialog>

       <!--忘记密码弹窗-->
       <el-dialog :close-on-click-modal="false" :visible.sync="pwdShow" width="22%">
           <ForgetPwd @change="pwdShow = false"></ForgetPwd>
       </el-dialog>
   </div>
</template>

<script>
import ExtractRecord from "@/pages/user/components/ExtractRecord.vue";
import ForgetPwd from "@/components/common/Pay/forgetPwd.vue";
export default {
    name: "PutMoney",
    components: {ExtractRecord,ForgetPwd},
    data() {
        return {
            withdrawaResult: 0, //0提现中 -1提现失败 1提现成功
            radio: '1',
            isChenkCard:false,
            input: '',
            withdrawaShow:false,
            withdrawaColor:false,
            pwdShow:false,
            pwdColor:false,
            money:'',
            payPwd:'',
            cashResult:{},
        }
    },

    props: {
        acctBalance:{
            type:Object,
            default() {
                return {};
            }
        }
    },

    computed:{
        user() {
            return this.$store.state.user;
        },
    },

    mounted() {
        console.log(this.acctBalance);
    },

   methods:{
        //检查金额
       checkMoney(){
          if(this.money > this.acctBalance.info.acct_balance){
                this.$message.error('提现金额不能大于账户余额');
                this.money = this.acctBalance.info.acct_balance;
          }
       },

       //提现提交
       alpplyBankCarCash(){
           var that = this;
           if (this.money == '') {
               this.$message.error('请输入提现金额');
               return false;
           }
           if (this.payPwd == '') {
               this.$message.error('请输入支付密码');
               return false;
           }
           if(this.acctBalance.memberId == ''){
                this.$message.error('用户memberId为空');
               return false;
           }
           if(this.radio == ''){
               this.$message.error('请选择银行卡');
               return false;
           }
           var params = {};
           params.cashAmt = that.utils.keepTwoDecimalFull(that.money);
           params.memberId = that.acctBalance.memberId;
           params.accountType = that.acctBalance.accType;
           params.payPwd = that.payPwd;
           that.openLoading('正在提现...');
           that.newApi.alpplyBankCarCash(params).then((res) => {
               if(res.isSuccess == 1){
                   that.$emit('change',that.acctBalance.accType);
                   that.money = '';
                   that.payPwd = '';
                   that.cashResult = res.data;
                   that.withdrawaResult = 1;
               }
           })
       },

       //继续提现
       continueToWithdraw(){
           this.money = '';
           this.payPwd = '';
           this.withdrawaResult = 0;
       }
   }
}
</script>

<style scoped>

</style>
