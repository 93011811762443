<template>
  <div>
    <div style="display: flex">
      <div class="hand op" style="width: 40px;height: 20px;margin:16px 20px 0 0;" @click="message">
        <el-badge :hidden="$store.state.systemCount==0" :max="99" :value="$store.state.systemCount" class="item" >
          <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/icon.png" style="width: 25px;height: 19px;">
        </el-badge>
      </div>
      <div>
        <el-dropdown @command="login_out" >
          <div class="hand op sc" style="width: 100%;height: auto;display: flex;text-align: right">
            <img :src="user.headIco" style="width: 26px;height: 26px;margin:12px 6px 0 0;border-radius: 50%;">
            <div style="color: #031F88;height: 50px;line-height: 50px;font-size: 15px;max-width: 210px">{{ user.nickName }}</div>
            <div style="line-height: 50px;"><i class="el-icon-caret-bottom el-icon--right" style="color: #031F88 "></i></div>
          </div>
          <!--  下拉菜单 -->
          <el-dropdown-menu slot="dropdown" style="margin-top: -5px;">
            <div style="width: 130px;height: 260px;background: #FFFFFF;">
              <div class="hand op sc" style="height: 30px;width: auto;" @click="circle">
                <div style="height: 20px;width: 20px;margin-left: 20px;float: left;">
                  <img src="~@/assets/imgs/index/self1.png" style="height: 20px;width: 20px;">
                </div>
                <div style="height: 24px; line-height: 24px;width: calc(100% - 48px);float: left;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;">
                  我的主页
                </div>
              </div>
              <div class="hand op sc" style="height: 30px;width:auto;" @click="a('/auth')">
                <div style="height: 20px;width: 20px;margin-left: 20px;float: left;margin-top: 8px;">
                  <img src="~@/assets/imgs/index/self2.png" style="height: 20px;width: 20px;">
                </div>
                <div style="height: auto;width: calc(100% - 48px);float: left;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 8px;">
                  认证中心
                </div>
              </div>
              <div class="hand op sc" style="height:30px;width: auto;margin-top: 8px" @click="a('/member')">
                <div style="height: 18px;width: 18px;margin: 8px 0 0 20px;float: left;">
                  <img src="~@/assets/imgs/index/self4.png" style="height: 18px;width: 18px;">
                </div>
                <div style="height: auto;width: calc(100% - 48px);float: left;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 8px;">
                  会员中心
                </div>
              </div>
              <div class="hand op sc" style="height: 30px;width: auto;margin: 8px 10px" @click="a('/index/ruleCenter')">
                <div style="height: 18px;width: 18px;margin: 8px 0 0 10px;float: left;">
                  <img src="~@/assets/imgs/index/self5.png" style="height: 18px;width: 18px;">
                </div>
                <div style="height: auto;width: calc(100% - 48px);float: left;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 6px;margin-top: 7px;">
                  规则中心
                </div>
              </div>
              <div class="hand op sc" style="height: 30px;width: auto;display: flex" @click="a('/index/help')">
                <div style="height: 17px;width: 17px;margin: 8px 0 0 20px;float: left;">
                  <img src="~@/assets/imgs/index/self6.png" style="height: 17px;width: 17px;">
                </div>
                <div style="height: auto;width: calc(100% - 48px);float: left;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 8px;">
                  帮助中心
                </div>
              </div>

              <div style="position: relative" @click="showLogin = true">
                <div style="height: 17px;width: 17px;margin: 15px 0 0 20px;float: left;">
                  <img src="~@/assets/imgs/index/qhzh.png" style="height: 17px;width: 17px;">
                </div>
                <div style="height: auto;width: calc(100% - 48px);float: left;font-size: 14px;font-weight: bold;cursor: pointer;margin-left: 5px;margin-top: 15px;">
                  切换账号
                </div>
              </div>
              <div style="position: relative">
                <div style="height: 30px;position: absolute;left: 20px;top: 50px;">
                  <el-dropdown-item command="/index/index" >退出登录</el-dropdown-item>
                </div>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>

</template>

<script>
import AccountSelection from "@/components/common/AccountSelection.vue";

export default {
  name: "UserTop",
  components: {AccountSelection},
  data() {
    return {
      showLogin:false,
    };
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    is_login() {
      return this.$store.state.is_login
    }
  },

  props: {},
  created() {

  },
  mounted() {
  },
  methods: {
    // 通知消息
    message(){
      window.open('/message/index')
    },

    login_out() {
      localStorage.token = '';
      localStorage.uuid = '';
      this.$router.push({ path: '/' });
      this.$store.commit("login_out");
      this.$store.state.subList = [];
      this.routes = '/';
      this.showLogin = false;
    },

    circle(){
      var that = this;
      if(that.user.userType === 1){
        that.utils.a('/circle/mySpace/' + that.user.uuid)
      }else if (that.user.userType === 2 || that.user.userType === 3)
        that.utils.a('/circle/organSpace/' + that.user.uuid)
    },
  }
}
</script>

<style scoped>

</style>
