<template>
    <div class="push-flow" style="padding: 30px 40px">
<!--        <div style="margin-bottom: 20px; display: flex; flex-wrap: wrap;">-->
<!--            <div class="hand op tag-btn" @click="codetype(0)" :style="type === 0 ? 'color: #FFFFFF; background-color: #2970FF' : ''">全部</div>-->
<!--            <div class="hand op tag-btn" @click="addtype(item.type)" :style="type === item.type ? 'color: #FFFFFF; background-color: #2970FF' : ''" v-for="(item, index) in namelist" :key="index"><span style="width: 16px; height: 16px; border-radius: 50%; display: inline-block; margin-right: 6px" :style="{'background-color': tagColors[index % tagColors.length]}"></span>{{item.name}}</div>-->
<!--        </div>-->

        <el-table
                :data="flowlist"
                :header-cell-style="{background:'#F4F4F4', color:'#444444'}"
                style="width: 100%; border: 1px #CCCCCC solid; font-size: 18px">
            <el-table-column
                width="260"
                align="center">
              <template slot="header" slot-scope="scope">
                <el-select size="small" v-model="value" @change="handleSelectChange" clearable placeholder="请选择">
                  <el-option
                      v-for="item in namelist"
                      :key="item.type"
                      :label="item.name"
                      :value="item.type">
                  </el-option>
                </el-select>
              </template>
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
                    label="支出时间"
                    width="260"
                    align="center">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.time }}</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="金额（元）"
                    width="240"
                    align="center">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">-{{ scope.row.value }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    label="总支出（元）"
                    align="center">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.percent }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        name: "PushFlow",
        data() {
            return {
                type: 0,
                value: "",
                tagColors: ['#DE5A4C', '#ED9A40', '#DD6A8E', '#7C65C4', '#58B8DA', '#8496CA', '#9EC656', '#84BCAC', '#F6CE03'],
                flowlist:[],
                namelist:[],
                flowtype:'0'
            }
        },

        mounted() {
            this.expenditureFlow(this.type)
        },

        methods: {
            addtype(type){
                const index = this.namelist.findIndex(item => item.type === type);
                this.type = type
                this.expenditureFlow(this.type)
            },
            //监听el-select
            handleSelectChange(val) {
                this.expenditureFlow(val)
            },

            //    查询支出流水
            expenditureFlow(flowtype){
                let that = this;
                that.newApi.expenditureFlow({type:flowtype,}).then((res)=>{
                    that.namelist = res.data.nameList;
                    that.flowlist = res.data.list;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            // 点击查看全部
            codetype(type){
                this.type = type
                this.expenditureFlow(type)
            }
        }
    }
</script>

<style scoped>
    .push-flow .tag-btn {
        padding: 2px 14px;
        border-radius: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px #999999 solid;
        font-size: 20px;
    }
</style>
