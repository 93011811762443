<template>
    <div style="width:calc(100% - 20px);height: 800px;margin: 10px;">
        <!--转账进行中-->
        <div v-if="transferResult == 0">
            <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                <el-tab-pane label="好友转账" name="first">
                    <div style="width: 95%;height: 27px;border: 1px solid #c1c1c1;margin: 0 auto;background: #FFFBCC;display: flex;">
                        <div style="width: 25px;height: 22px;margin-top: 2.5px;margin-left: 2px;">
                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/jg2.png">
                        </div>
                        <div style="width:calc(100% - 25px);line-height: 27px;font-size: 14px;text-indent: 8px;color: #F24A30;">温馨提示：系统会自动将转账结果通知收款人</div>
                    </div>
                    <div style="width: 800px;height: 300px;margin-left: 27px;margin-top: 15px;">
                        <div style="width: 100%;height:40px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">收款人:</div>
                            <div style="width: 200px;height: 40px;margin-left: 15px;">
                                <template>
                                    <el-select v-model="friendId" filterable placeholder="请选择">
                                        <el-option v-for="(item,index) in friendsList" :key="index" :label="item.feiendsRemarks" :value="index" :disabled="item.disabled">
                                            <div v-if="item.realNameStatus == 0">
                                                <span>{{item.feiendsRemarks}}(未实名认证)</span>
                                            </div>
                                            <div v-if="item.realNameStatus == 1">
                                                <span>{{item.feiendsRemarks}}(实名认证审核中)</span>
                                            </div>
                                            <div v-if="item.realNameStatus == 2">
                                                <span v-if="item.disabled == false">{{item.feiendsRemarks}}</span>
                                                <span v-if="item.disabled == true">{{item.feiendsRemarks}}(未绑卡)</span>
                                            </div>

                                        </el-option>
                                    </el-select>
                                </template>
                            </div>
                            <div @click="showFriend = true" style="width: 40px;height: 40px;margin-left: 2px;cursor: pointer;">
                                <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/hy.png">
                            </div>
                            <div style="width: 140px;line-height: 40px;margin-left: 2px;color: #3D9CE8;">(点击头像快速选择)</div>
                            <!--好友转账弹窗-->
                            <el-dialog  style="padding: 0px;"  :visible.sync="showFriend" width="30%" :close-on-click-modal="false" class="show-message">
                                <el-tabs v-model="friendTransfer" type="border-card">
                                    <el-tab-pane label="最近转账" name="first">
                                        <span v-if="transferList.friend.length == 0">{{f_transfer_text}}</span>
                                        <div v-if="transferList.friend.length > 0">{
                                            <div v-for="(item,index) in transferList.friend" :key="index">
                                                <el-radio v-if="item.disabled == true" v-model="friendRadio" @input="checkFriendRadio" :label="index">{{item.nickName}}({{item.phone}}) <span style="color: #c1c1c1;">上次转账时间：{{item.time}}</span></el-radio>
                                                <el-radio v-if="item.disabled == false" disabled v-model="friendRadio" @input="checkFriendRadio" :label="index">{{item.nickName}}({{item.phone}}) <span style="color: #c1c1c1;">上次转账时间：{{item.time}} (未绑卡)</span></el-radio>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-dialog>
                        </div>
                        <div style="width: 100%;height:40px;margin-top:30px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">付款金额:</div>
                            <div style="width: 200px;height: 40px;margin-left: 15px;">
                                <template>
                                    <el-input type="number" v-model="f_money" placeholder="请输入金额"></el-input>
                                </template>
                            </div>
                            <div style="width: 30px;line-height: 40px;padding-left: 5px;">元</div>
                        </div>
                        <div style="width: 100%;height:240px;margin-top:30px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">付款说明:</div>
                            <div style="width: 500px;height: 240px;margin-left: 15px;">
                                <template>
                                    <el-input type="textarea" v-model="f_remark" :maxlength="50"></el-input>
                                </template>
                                <div style="font-size: 12px;line-height: 30px;color: #c1c1c1;">备注内容对方可以看到。(最多50个字）</div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 150px;height: 50px;margin-left: 200px;">
                        <el-button type="primary" @click="f_nextStep">下一步</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="手机号转账" name="second">
                    <div style="width: 95%;height: 27px;border: 1px solid #c1c1c1;margin: 0 auto;background: #FFFBCC;display: flex;">
                        <div style="width: 25px;height: 22px;margin-top: 2.5px;margin-left: 2px;">
                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/jg2.png">
                        </div>
                        <div style="width:calc(100% - 25px);line-height: 27px;font-size: 14px;text-indent: 8px;color: #F24A30;">温馨提示：系统会自动将转账结果通知收款人</div>
                    </div>
                    <div style="width: 800px;height: 420px;margin-left: 27px;margin-top: 15px;">
                        <div style="width: 100%;height:40px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">收款人:</div>
                            <div style="width: 200px;height: 40px;margin-left: 15px;">
                                <template>
                                    <el-input v-if="mobileText == ''" @blur="mobileBlur" v-model="mobile" placeholder="收款人手机号" :maxlength="11"></el-input>
                                    <el-input v-if="mobileText != ''" @blur="mobileBlur" v-model="mobileText" :maxlength="11"></el-input>
                                </template>
                            </div>
                            <div @click="showMobile = true" style="width: 40px;height: 40px;margin-left: 2px;cursor: pointer;">
                                <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/hy.png">
                            </div>
                            <div style="width: 140px;line-height: 40px;margin-left: 2px;color: #3D9CE8;">(点击头像快速选择)</div>
                            <!--手机号转账弹窗-->
                            <el-dialog style="padding: 0px;" :visible.sync="showMobile" width="30%" :close-on-click-modal="false" class="show-message">
                                <el-tabs v-model="mobileTransfer" type="border-card">
                                    <el-tab-pane label="最近转账" name="first">
                                        <span v-if="transferList.phone.length == 0">{{m_transfer_text}}</span>
                                        <div v-if="transferList.phone.length > 0">
                                            <div v-for="(item,index) in transferList.phone" :key="index">
                                                <el-radio v-if="item.disabled == true"  v-model="phoneRadio" @input="checkPhoneRadio" :label="index">{{item.nickName}}({{item.phone}}) <span style="color: #c1c1c1;">上次转账时间：{{item.time}}</span></el-radio>
                                                <el-radio v-if="item.disabled == false" disabled v-model="phoneRadio" @input="checkPhoneRadio" :label="index">{{item.nickName}}({{item.phone}}) <span style="color: #c1c1c1;">上次转账时间：{{item.time}} (未绑卡)</span></el-radio>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-dialog>
                        </div>
                        <div style="width: 100%;height:20px;margin-top:10px;display: flex;">
                            <div style="width: 160px;line-height: 20px;text-align: right;"></div>
                            <div style="width: 200px;height: 20px;margin-left: 15px;">
                                <template>
                                    <el-checkbox v-model="checkedRealName">效验收款人姓名<span style="font-size: 12px;color: red;">(非必选)</span></el-checkbox>
                                </template>
                            </div>
                        </div>
                        <div v-if="checkedRealName == true" style="width: 100%;height:40px;margin-top:20px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">收款人姓名:</div>
                            <div style="width: 200px;height: 40px;margin-left: 15px;">
                                <template>
                                    <el-input @blur="validationRealName" v-model="collectionRealName" placeholder="请输入收款人姓名"></el-input>
                                </template>
                            </div>
                        </div>
                        <div style="width: 100%;height:40px;margin-top:20px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">付款金额:</div>
                            <div style="width: 200px;height: 40px;margin-left: 15px;">
                                <template>
                                    <el-input type="number" v-model="m_money" placeholder="请输入金额"></el-input>
                                </template>
                            </div>
                            <div style="width: 30px;line-height: 40px;padding-left: 5px;">元</div>
                        </div>
                        <div style="width: 100%;height:100px;margin-top:30px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">付款说明:</div>
                            <div style="width: 500px;height: 100px;margin-left: 15px;">
                                <template>
                                    <el-input type="textarea" v-model="m_remark" :maxlength="50"></el-input>
                                </template>
                                <div style="font-size: 12px;line-height: 30px;color: #c1c1c1;">备注内容对方可以看到。(最多50个字）</div>
                            </div>
                        </div>
                        <div style="width: 150px;height: 50px;margin-left: 175px;margin-top: 50px;">
                            <el-button @click="m_nextStep" type="primary">下一步</el-button>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="对公账户转账" name="third">
                    <div style="width: 95%;height: 27px;border: 1px solid #c1c1c1;margin: 0 auto;background: #FFFBCC;display: flex;">
                        <div style="width: 25px;height: 22px;margin-top: 2.5px;margin-left: 2px;">
                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/jg2.png">
                        </div>
                        <div style="width:calc(100% - 25px);line-height: 27px;font-size: 14px;text-indent: 8px;color: #F24A30;">温馨提示：系统会自动将转账结果通知收款人</div>
                    </div>
                    <div style="width: 800px;height: 500px;margin-left: 27px;margin-top: 15px;">
                        <div style="width: 100%;height:40px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">机构名称:</div>
                            <div style="width: 400px;height: 40px;margin-left: 15px;">
                                <template>
                                    <template>
                                        <el-input @blur="organBlur" v-model="organName" placeholder="输入机构全称" :maxlength="100"></el-input>
                                    </template>
                                </template>
                            </div>
                            <div @click="showOrgan = true" style="width: 40px;height: 40px;margin-left: 2px;cursor: pointer;">
                                <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/hy.png">
                            </div>
                            <div style="width: 140px;line-height: 40px;margin-left: 2px;color: #3D9CE8;">(点击头像快速选择)</div>
                            <!--对公账户转账弹窗-->
                            <el-dialog style="padding: 0px;" :visible.sync="showOrgan" width="30%" :close-on-click-modal="false" class="show-message">
                                <el-tabs v-model="organTransfer" type="border-card">
                                    <el-tab-pane label="最近转账" name="first">
                                        <span v-if="transferList.corp.length == 0">{{c_transfer_text}}</span>
                                        <div v-if="transferList.corp.length > 0">
                                            <div v-for="(item,index) in transferList.corp" :key="index">
                                                <el-radio v-if="item.disabled == true" v-model="corpRadio" @input="checkOrganRadio" :label="index">{{item.nickName}}({{item.phone}}) <span style="color: #c1c1c1;">上次转账时间：{{item.time}}</span></el-radio>
                                                <el-radio v-if="item.disabled == false" disabled v-model="corpRadio" @input="checkOrganRadio" :label="index">{{item.nickName}}({{item.phone}}) <span style="color: #c1c1c1;">上次转账时间：{{item.time}} (未绑卡)</span></el-radio>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-dialog>
                        </div>
                        <div v-if="JSON.stringify(organInfo) != '{}'">
                            <div style="width: 100%;height:40px;margin-top:20px;display: flex;">
                                <div style="width: 160px;line-height: 40px;text-align: right;">组织机构代码:</div>
                                <div style="width: 200px;height: 40px;margin-left: 15px;">
                                    <template>
                                        <el-input v-model="organInfo.codeCertificate" disabled="disabled" placeholder="组织机构代码"></el-input>
                                    </template>
                                </div>
                            </div>
                            <div style="width: 100%;height:40px;margin-top:20px;display: flex;">
                                <div style="width: 160px;line-height: 40px;text-align: right;">开户银行:</div>
                                <div style="width: 200px;height: 40px;margin-left: 15px;">
                                    <template>
                                        <el-input v-model="organInfo.bankName" disabled="disabled" placeholder="开户银行"></el-input>
                                    </template>
                                </div>
                            </div>
                            <div style="width: 100%;height:40px;margin-top:20px;display: flex;">
                                <div style="width: 160px;line-height: 40px;text-align: right;">银行账号:</div>
                                <div style="width: 200px;height: 40px;margin-left: 15px;">
                                    <template>
                                        <el-input v-model="organInfo.cardNo" disabled="disabled" placeholder="银行账号"></el-input>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;height:40px;margin-top:20px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">付款金额:</div>
                            <div style="width: 200px;height: 40px;margin-left: 15px;">
                                <template>
                                    <el-input type="number" v-model="o_money" placeholder="请输入金额"></el-input>
                                </template>
                            </div>
                            <div style="width: 30px;line-height: 40px;padding-left: 5px;">元</div>
                        </div>
                        <div style="width: 100%;height:100px;margin-top:30px;display: flex;">
                            <div style="width: 160px;line-height: 40px;text-align: right;">付款说明:</div>
                            <div style="width: 500px;height: 100px;margin-left: 15px;">
                                <template>
                                    <el-input type="textarea" v-model="o_remark" :maxlength="50"></el-input>
                                </template>
                                <div style="font-size: 12px;line-height: 30px;color: #c1c1c1;">备注内容对方可以看到。(最多50个字）</div>
                            </div>
                        </div>
                        <div style="width: 150px;height: 50px;margin-left: 175px;margin-top: 50px;">
                            <el-button @click="o_nextStep" type="primary">下一步</el-button>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <!--转账成功提示-->
        <div v-if="transferResult == 1">
            <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                <el-tab-pane label="好友转账" name="first">
                    <div style="width: 100%;height: 90px;background: #EDFFCC;display: flex;">
                        <div style="width: 40px;height: 40px;margin-top: 28px;margin-left: 30px;">
                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/cg.png">
                        </div>
                        <div style="width: calc(100% - 75px);line-height: 96px;padding-left: 15px;font-weight: bold;">
                            转账成功，对方已收到您的款项
                        </div>
                    </div>
                    <div style="width: calc(100% - 85px);line-height: 65px;padding-left: 35px;font-size: 14px;color: #888A88;display: flex;">
                        <div @click="transferResult = 0" style="width: 70px;color: blue;cursor: pointer;">继续转账</div>
                        <div @click="a('/')" style="width: 100px;color: blue;cursor: pointer;">返回首页</div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="手机号转账" name="second">
                    <div style="width: 100%;height: 90px;background: #EDFFCC;display: flex;">
                        <div style="width: 40px;height: 40px;margin-top: 28px;margin-left: 30px;">
                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/cg.png">
                        </div>
                        <div style="width: calc(100% - 75px);line-height: 96px;padding-left: 15px;font-weight: bold;">
                            转账成功，对方已收到您的元款项
                        </div>
                    </div>
                    <div style="width: calc(100% - 85px);line-height: 65px;padding-left: 35px;font-size: 14px;color: #888A88;display: flex;">
                        <div @click="transferResult = 0" style="width: 70px;color: blue;cursor: pointer;">继续转账</div>
                        <div @click="a('/')" style="width: 100px;color: blue;cursor: pointer;">返回首页</div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="对公账户转账" name="third">
                    <div style="width: 100%;height: 90px;background: #EDFFCC;display: flex;">
                        <div style="width: 40px;height: 40px;margin-top: 28px;margin-left: 30px;">
                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/cg.png">
                        </div>
                        <div style="width: calc(100% - 75px);line-height: 96px;padding-left: 15px;font-weight: bold;">
                            转账成功，对方已收到您的元款项
                        </div>
                    </div>
                    <div style="width: calc(100% - 85px);line-height: 65px;padding-left: 35px;font-size: 14px;color: #888A88;display: flex;">
                        <div @click="transferResult = 0" style="width: 70px;color: blue;cursor: pointer;">继续转账</div>
                        <div @click="a('/')" style="width: 100px;color: blue;cursor: pointer;">返回首页</div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>

</template>
<script>
export default {
    data() {
        return {
            transferResult:0, //-1转账失败 0转账中  1转账成功
            type:2, //实时转账
            payType:5, //转账
            activeName: 'first',
            transferList:[],
            //好友转账
            showFriend:false,
            friendTransfer: 'first',
            f_transfer_text: '暂无信息',
            friendRadio:'',
            friendId:'',
            friendsList:[],
            f_remark:'',
            f_money:'',
            //手机号转账
            showMobile:false,
            checkedRealName:false,
            mobileTransfer: 'first',
            m_transfer_text: '暂无信息',
            phoneRadio:'',
            mobile:'',
            mobileInfo:{},
            mobileText:'',
            collectionRealName:'',
            m_remark:'',
            m_money:'',
            //对公账户转账
            showOrgan:false,
            organTransfer: 'first',
            c_transfer_text: '暂无信息',
            corpRadio:'',
            organName:'',
            organInfo:{},
            o_money:'',
            o_remark:'',
            sign:'',
        };
    },

    created() {
        var that = this;
        //预支付订单监听
        this.$EventBus.$EventBus.$off("prePayOrder");
        this.$EventBus.$on("prePayOrder", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    that.bankPay(data.toPay);
                }
            });
        });
    },

    mounted() {
        var that = this;
        this.getWalletcFriendList();
        this.getRecentTransferList();
        // 先销毁事件，防止重复触发
        this.$EventBus.$EventBus.$off("payMsg")
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                if(data.code == 100){
                    that.transferResult = 1;
                    if(that.activeName == 'first'){
                        that.friendId = '';
                        that.f_money = '';
                        that.f_remark = '';
                    }else if (that.activeName == 'second') {
                        that.mobile = '';
                        that.mobileText = '';
                        that.checkedRealName = false;
                        that.mobileInfo = {};
                        that.collectionRealName = '';
                        that.m_money = '';
                        that.m_remark = '';
                    }else if (that.activeName == 'third') {
                        that.organName = '';
                        that.organInfo = {};
                        that.o_money = '';
                        that.o_remark = '';
                    }
                }
            });
        });
    },
    methods: {
        handleClick() {
            console.log(this.activeName);
        },

        //获取好友列表
        getWalletcFriendList(){
            var that = this;
            this.newApi.getWalletcFriendList().then((res) => {
                that.friendsList = res.data;
            });
        },

        //监听好友选择
        checkFriendRadio(val){
            var that = this;
            let uuid = that.transferList.friend[val].collectionUuid;
            that.friendsList.forEach((item,index) => {
                if(item.uuid == uuid){
                    that.friendId = index;
                    that.showFriend = false;
                }
            })
        },

        //手机号搜索 失去焦点
        mobileBlur(){
            var that = this;
            // 正则表达式用来匹配手机号
            let phoneNumberPattern = /^1[3-9]\d{9}$/;
            // 检查手机号是否符合规则
            if (!phoneNumberPattern.test(this.mobile)) {
                that.utils.err("手机号格式不正确");
                return false;
            }
            var params = {};
            params.phone = this.mobile;
            that.newApi.adapayCheckedMoile(params).then((res) => {
                if(res.isSuccess == 1){
                    that.mobileInfo = res.data;
                    that.mobileText = res.data.realName + '(' + res.data.phone +')'
                }
            })
        },

        //监听手机号选择
        checkPhoneRadio(val){
            var that = this;
            let uuid = that.transferList.phone[val].collectionUuid;
            that.newApi.getWalletCardPhone({uuid:uuid}).then((res) => {
                if(res.isSuccess == 1){
                    that.mobile = res.data;
                    that.mobileBlur();
                    that.showMobile = false;
                }
            })
        },

        //效验收款人姓名
        validationRealName(){
            var that = this;
            var params = {};
            params.uuid = this.mobileInfo.uuid;
            params.realName = this.collectionRealName;
            that.newApi.getvalidationRealName(params).then((res) => {
                if(res.isSuccess == 1){
                    that.utils.sus(res.data);
                }
            })
        },

        //机构名称效验 失去焦点
        organBlur(){
            var that = this;
            var params = {};
            params.name = this.organName;
            that.newApi.checkCorpName(params).then((res) => {
                if(res.isSuccess == 1){
                    if(JSON.stringify(res.data) == '{}'){
                        that.utils.err("未查询到该机构信息");
                        return false;
                    }
                    that.organName = res.data.corpoName;
                    that.organInfo = res.data;
                    that.utils.sus("查询完成");
                }
            })
        },

        //监听对公列表选择
        checkOrganRadio(val){
            var that = this;
            let name = that.transferList.corp[val].nickName;
            that.organName = name;
            that.organBlur();
            that.showOrgan = false;
        },

        //最近转账人列表
        getRecentTransferList(){
          var that = this;
            this.newApi.getRecentTransferList({}).then((res) => {
                if(res.isSuccess == 1){
                   that.transferList = res.data;
                }
            });
        },

        //好友转账下一步
        f_nextStep(){
            var that = this;
            if(that.friendsList.length == 0){
                that.utils.err("请选择收款人");
                return false;
            }

            if(that.f_money == '' || that.f_money == 0){
                that.utils.err("请输入付款金额");
                return false;
            }

            let params = that.$store.state.payParams;
            params.type = that.type;
            params.payType = that.payType;
            params.payAmt = that.f_money;
            params.memberId = that.friendsList[that.friendId].memberId;
            params.collectionUuid = that.friendsList[that.friendId].uuid;
            params.remark = that.f_remark;

            that.sign = that.utils.random_string(6);
            that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                that.b('/newAdaPay/?sign='+that.sign);
            })
        },

        //手机转账转账下一步
        m_nextStep(){
            var that = this;
            if(that.mobileText == ''){
                that.utils.err("请收款人手机号码");
                return false;
            }

            if(that.checkedRealName == true){
                if(that.collectionRealName == '' ){
                    that.utils.err("请输入正确的付款人姓名");
                    return false;
                }
            }

            if(that.m_money == '' || that.m_money == 0){
                that.utils.err("请输入付款金额");
                return false;
            }

            let params = that.$store.state.payParams;
            params.type = that.type;
            params.payType = that.payType;
            params.payAmt = that.m_money;
            params.memberId = that.mobileInfo.memberId;
            params.collectionUuid = that.mobileInfo.uuid;
            params.remark = that.m_remark;

            that.sign = that.utils.random_string(6);
            that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                that.b('/newAdaPay/?sign='+that.sign);
            })
        },

        //对公转账转账下一步
        o_nextStep(){
            var that = this;
            if(JSON.stringify(that.organInfo) == '{}'){
                that.utils.err("请输入正确的机构名称");
                return false;
            }

            if(that.o_money == '' || that.o_money == 0){
                that.utils.err("请输入付款金额");
                return false;
            }

            let params = that.$store.state.payParams;
            params.type = that.type;
            params.payType = that.payType;
            params.payAmt = that.o_money;
            params.memberId = that.organInfo.memberId;
            params.collectionUuid = that.organInfo.uuid;
            params.remark = that.o_remark;

            that.sign = that.utils.random_string(6);
            that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                that.b('/newAdaPay/?sign='+that.sign);
            })
        },


        bankPay(data){
            var that = this;
            var params = {};
            params.orderNo = data.orderNo;
            params.code = data.code;
            params.payPwd = data.payPwd;
            //判断是对公还是公绑私
            if(data.activeName != 'corporate'){
                that.newApi.settleAccountTransferDetermine(params).then(() => {});
            }
        },
    }
};
</script>

<style>
.el-dialog__body {
    padding: 10px 5px;
}
.el-textarea__inner{
    height: 100px !important;
}
.el-radio{
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>